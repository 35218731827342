var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "title" }, [
        _c("span", { staticClass: "title_icon" }),
        _vm._v(_vm._s(_vm.isEdit ? "编辑" : "添加") + "订单"),
      ]),
      _c(
        "div",
        { staticClass: "Wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "330px", float: "left" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.plate_number"),
                    prop: "plateNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !_vm.parkCardIsActive && !_vm.isNowDate,
                      maxlength: 8,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.formInline.plateNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "plateNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.plateNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.License_plate_color"),
                    prop: "plateColor",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: !_vm.parkCardIsActive && !_vm.isNowDate,
                        placeholder: "请选择车牌颜色",
                      },
                      model: {
                        value: _vm.formInline.plateColor,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "plateColor",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.plateColor",
                      },
                    },
                    _vm._l(_vm.colorList, function (v) {
                      return _c("el-option", {
                        key: v.code,
                        attrs: { label: v.desc, value: v.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "phoneNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isEdit,
                      maxlength: 11,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.formInline.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "phoneNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.phoneNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Parking_card_name"),
                    prop: "parkCardRuleId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isEdit,
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.parkCardRuleIdChange },
                      model: {
                        value: _vm.formInline.parkCardRuleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "parkCardRuleId", $$v)
                        },
                        expression: "formInline.parkCardRuleId",
                      },
                    },
                    _vm._l(_vm.ruleList, function (item) {
                      return _c("el-option", {
                        key: item.parkCardRuleId,
                        attrs: { label: item.name, value: item.parkCardRuleId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  staticStyle: { width: "630px" },
                  attrs: { label: "适用范围" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      autosize: { minRows: 2 },
                      type: "textarea",
                    },
                    model: {
                      value: _vm.formInline.useRangeDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "useRangeDesc", $$v)
                      },
                      expression: "formInline.useRangeDesc",
                    },
                  }),
                ],
                1
              ),
              _vm.formInline.effectType === 0
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        label: "生效开始日期",
                        disabled: "",
                        prop: "effectStartDate",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: "",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.formInline.effectStartDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "effectStartDate", $$v)
                          },
                          expression: "formInline.effectStartDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formInline.effectType === 0
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { label: "生效结束日期", prop: "effectEndDate" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: "",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.formInline.effectEndDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "effectEndDate", $$v)
                          },
                          expression: "formInline.effectEndDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formInline.effectType === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: " ",
                        "label-width": "1px",
                        prop: "effectDaysAfterBuy",
                      },
                    },
                    [
                      _c("div", { staticStyle: { color: "#606266" } }, [
                        _vm._v(" 自购买后"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0f6eff",
                              "font-size": "20px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formInline.effectDaysAfterBuy) +
                                "天 "
                            ),
                          ]
                        ),
                        _vm._v("开始生效 "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.formInline.effectType === 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "有效天数" },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 100, disabled: "" },
                        model: {
                          value: _vm.formInline.validityPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "validityPeriod",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.validityPeriod",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "left", "margin-left": "8.5%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "primary" },
              on: { click: _vm.addEdit },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }